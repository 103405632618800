import './index.scss';

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from 'react-loaders';

import AnimatedLetters from '../AnimatedLetters';
import LetterL from '../../assets/images/letter-l.png';
import LetterT from '../../assets/images/letter-t.png';
import MarseilleCity from '../../assets/images/marseille.png';

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const nameArray = 'uc'.split('')
  const surnameArray = 'atu'.split('')
  const cityArray = 'From MARSEILLE'.split('')
  const jobArray = 'java developer.'.split('')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  })

  return (
    <>
      <div className="container">
        <div className="container home-page">
          <div className="text-zone">
            <h1>
              <span className={letterClass}>H</span>
              <span className={`${letterClass} _12`}>i,</span>
              <br />
              <span className={`${letterClass} _13`}>I</span>
              <span className={`${letterClass} _14`}>'m</span>
              <img
                src={LetterL}
                alt="Name"
                width="50000"
                height="200"
              />
              <AnimatedLetters
                letterClass={letterClass}
                strArray={nameArray}
                idx={15}
              />
              <img
                src={LetterT}
                alt="Surname"
              />
              <AnimatedLetters
                letterClass={letterClass}
                strArray={surnameArray}
                idx={15}
              />
              <br />
              <AnimatedLetters
                letterClass={letterClass}
                strArray={jobArray}
                idx={20}
              />
            </h1>
            <h2>Back End Service & Software Developer</h2>
            <Link to="/contact" className="flat-button">
              CONTACT ME
            </Link>
          </div>
        </div>
        <div className="right-zone">
          <img src={MarseilleCity} alt="Home sweet home" />
          <h3>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={cityArray}
              idx={20}
            />
          </h3>
        </div>
      </div>
      <Loader type="pacman" active />
    </>
  )
}

export default Home
