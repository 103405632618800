import React, { useEffect, useState } from 'react';
import TextSphere from './TextSphere.js';
import AnimatedLetters from '../AnimatedLetters';
import file from "../../data/skills.json";
import Loader from 'react-loaders';
import './index.scss';

const Skills = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const titlePage = 'My skills'.split('');
  const skills = file.skills;

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 4000);
  }, []);

  const splitSkillsIntoColumns = () => {
    const midIndex = Math.ceil(skills.length / 2);
    const firstColumn = skills.slice(0, midIndex);
    const secondColumn = skills.slice(midIndex);
    return [firstColumn, secondColumn];
  };

  const [firstColumnSkills, secondColumnSkills] = splitSkillsIntoColumns();

  return (
    <>
      <div className="container skills-page">
        <div className="page-container">
          <div className="leftSide">
            <div className="text-zone">
              <h1>
                <AnimatedLetters
                  letterClass={letterClass}
                  strArray={titlePage}
                  idx={15}
                />
              </h1>
              <div className="skills-container">
                <ul className="skills-list">
                  {firstColumnSkills.map((skill, index) => (
                    <li key={index} className={`ls _${index + 1}`}>
                      👉🏻 {skill}
                    </li>
                  ))}
                </ul>
                {secondColumnSkills.length > 0 && (
                  <ul className="skills-list">
                    {secondColumnSkills.map((skill, index) => (
                      <li
                        key={index + firstColumnSkills.length}
                        className={`ls _${index + firstColumnSkills.length + 1}`}
                      > 👉🏻 {skill}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="rightSide">
            <TextSphere />
          </div>
        </div>
      </div>
      <Loader type="pacman" active />
    </>
  );
};

export default Skills;