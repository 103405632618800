import './index.scss';

import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faOdysee, faGitlab, faInstagram } from '@fortawesome/free-brands-svg-icons';
import {
  faHome,
  faUser,
  faEnvelope,
  faBars,
  faClose,
  faSuitcase,
  faPlane,
  faCode,
} from '@fortawesome/free-solid-svg-icons'

import Me from '../../assets/images/me.png';

const Sidebar = () => {
  const [showNav, setShowNav] = useState(false);
  const [rotate, setRotate] = useState(false);

  const rotateImage = () => {
    setRotate(true);
    setTimeout(() => setRotate(false), 1000); // Adjust the timeout as needed (1s in this case)
  };

  return (
    <div className="nav-bar">
      <Link
        className={`logo ${rotate ? 'rotate' : ''}`}
        to="/"
        onClick={() => {
          setShowNav(false);
          rotateImage();
        }}
      >
        <img src={Me} alt="Logo"/>
      </Link>
      <nav className={showNav ? 'mobile-show' : ''}>
        <NavLink
          exact="true"
          activeclassname="active"
          to="/"
          onClick={() => setShowNav(false)}>
          <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="about-link"
          to="/about"
          onClick={() => setShowNav(false)}>
          <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="portfolio-link"
          to="/portfolio"
          onClick={() => setShowNav(false)} >
          <FontAwesomeIcon icon={faSuitcase} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="travels-link"
          to="/travels"
          onClick={() => setShowNav(false)} >
          <FontAwesomeIcon icon={faPlane} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="skills-link"
          to="/skills"
          onClick={() => setShowNav(false)} >
          <FontAwesomeIcon icon={faCode} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="contact-link"
          to="/contact"
          onClick={() => setShowNav(false)} >
          <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
        </NavLink>
        <FontAwesomeIcon
          onClick={() => setShowNav(false)}
          icon={faClose}
          color="#ffd700"
          size="3x"
          className='close-icon' />
      </nav>
      <ul>
        <li>
          <a href="https://fr.linkedin.com/in/luc-tatu/"
            target="_blank"
            rel="noreferrer" >
            <FontAwesomeIcon icon={faLinkedin}
              color="#4d4d4e"
              className="anchor-icon" />
          </a>
        </li>
        <li>
          <NavLink
            activeclassname="active"
            className="contact-link"
            to="/dashboard"
            onClick={() => setShowNav(false)} >
            <FontAwesomeIcon icon={faOdysee}
              color="#4d4d4e"
              className="anchor-icon" />
          </NavLink>
        </li>
        <li>
          <a href="https://gitlab.com/LucTATU/"
            rel="noreferrer"
            target="_blank" >
            <FontAwesomeIcon icon={faGitlab}
              color="#4d4d4e"
              className="anchor-icon" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/luctatu/"
             rel="noreferrer"
             target="_blank">
            <FontAwesomeIcon icon={faInstagram}
              color="#4d4d4e"
              className="anchor-icon" />
          </a>
        </li>
      </ul>
      <FontAwesomeIcon
        onClick={() => setShowNav(true)}
        icon={faBars}
        color="#ffd700"
        size="3x"
        className='hamburger-icon' />
    </div>
  )
}

export default Sidebar
