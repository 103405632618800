import './index.scss';

import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { db } from '../../firebase'
import { addDoc, collection } from 'firebase/firestore'
import { GeoPoint } from 'firebase/firestore';

const Position = () => {
  const [address, setAddress] = useState("Prague, Tchéquie");
  const [coordinates, setCoordinates] = useState([50.0755381, 14.4378005]);
  const [city, setCity] = useState('Prague');
  const [country, setCountry] = useState('Tchéquie');

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const li = await getLatLng(results[0]);

    setAddress(value);
    setCoordinates([li.lat, li.lng]);

    const addressData = value.trim().split(',');
    setCity(addressData[0].trim());
    setCountry(addressData[addressData.length - 1].trim());
  };

  function FlyMapTo() {
    const map = useMap();

    useEffect(() => {
      map.flyTo(coordinates)
    }, [coordinates]);

    return null
  }

  const submitPosition = async (e) => {
    e.preventDefault();
    const travel = {
      city: city,
      country: country,
      coordinates: new GeoPoint(coordinates[0], coordinates[1])
    }
    try {
      await addDoc(collection(db, 'travels'), travel);
      alert("New travel data sent to firebase:\n" + travel.city )
    } catch (error) {
      alert("Failed to add travel:\n" + error);
    }
  }

  return (
    <div className="position-container">
      <h1 className="new-position-title"> New Position </h1>
      <div className="position-data">
        <div className="top-position-data">
          <div className="left-data">
            <div>City: {city} </div>
            <div>Country: {country} </div>
          </div>
          <div className="right-data">
            <div>Latitude: {coordinates[0]} </div>
            <div>Longitude: {coordinates[1]} </div>
          </div>
        </div>
        <div className="bottom-position-data">
          <div>Address: {address} </div>
        </div>
      </div>

      <div className="map">
        <MapContainer center={coordinates} zoom={12}>
          <FlyMapTo />
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={coordinates} />
        </MapContainer>
      </div>

      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div key={suggestions.description}>
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#fafafa", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <button type="submit" onClick={submitPosition} >Submit</button>
    </div>
  );
};

export default Position;
