import './index.scss';

import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import Project from "../Project";
import Login from '../Login';
import Position from '../Position';

const Dashboard = () => {

  const [user, setUser] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if(user) {
        setUser(user);
      } else {
        setUser(null);
      }
    })
  });


  return (
    <div>
      {user ?
        <div className="dashboard" >
          <Project />
          <Position />
        </div>
        :
        <Login />
      }
    </div>
  )
}

export default Dashboard;
