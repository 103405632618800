import './index.scss';

import AnimatedLetters from '../AnimatedLetters';
import Loader from 'react-loaders';
import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const Travels = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [travels, setTravels] = useState([]);
  const [shuffledData, setShuffledData] = useState([]);
  const initialPosition = { coordinates: [0, 0], zoom: 1 };
  const [position, setPosition] = useState(initialPosition);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const pageTitle = 'My travels'.split('');
  const markerSize = 10 / position.zoom;

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);

    getTravels().then(r => {});
  }, []);

  useEffect(() => {
    shuffleArray();
  }, [travels]);

  const handleMoveEnd = (newPosition) => {
    setPosition(newPosition);
  };

  const handleMarkerClick = (index) => {
    setSelectedMarker(index);
  };

  const closePopup = () => {
    setSelectedMarker(null);
  };

  const handleReset = () => {
    setPosition(initialPosition);
  };

  const shuffleArray = () => {
    if (travels.length > 0) {
      const shuffledArray = travels.slice();
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      setShuffledData(shuffledArray);
    } else {
      setShuffledData([]);
    }
  };

  const getTravels = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'travels'));
      const travelsData = querySnapshot.docs.map((doc) => doc.data());
      setTravels(travelsData);
    } catch (error) {
      alert("Error fetching travels:\n" + error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="map-container">
          <ComposableMap projectionConfig={{ scale: 150 }} className="composable-map">
            <ZoomableGroup
              zoom={position.zoom}
              center={position.coordinates}
              onMoveEnd={handleMoveEnd}
            >
              <Geographies geography="/features.json">
                {({ geographies }) =>
                  geographies.map((geo) => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      style={{
                        default: {
                          fill: "#F7F9F9",
                          outline: "none",
                        },
                        hover: {
                          fill: "#AED6F1",
                          outline: "none",
                        },
                        pressed: {
                          outline: "none",
                        },
                      }}
                    />
                  ))
                }
              </Geographies>

                {shuffledData.map((positionData, index) => (
                  <Marker
                    className={`marker _${index + 1}`}
                    key={index}
                    coordinates={[positionData.coordinates._long, positionData.coordinates._lat]}
                    onClick={() => handleMarkerClick(index)}
                  >
                    <text fontSize={markerSize}>📍</text>
                  </Marker>
                ))}

              </ZoomableGroup>
            </ComposableMap>
            <button className="reset-button" onClick={handleReset}>
              Reset View
            </button>
          </div>
          <div className="title-container">
            <h6>
              <AnimatedLetters
                letterClass={letterClass}
                strArray={pageTitle}
                idx={15}
              />
              {selectedMarker !== null && (
                <div className="marker-popup" >
                  <p>{shuffledData[selectedMarker].city}</p>
                  <p>{shuffledData[selectedMarker].country}</p>
                  <button onClick={closePopup}>Close Popup</button>
                </div>
              )}
            </h6>
          </div>
        </div>
      <Loader type="pacman" active />
    </>
  );
}

export default Travels;
