import './index.scss';

import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Loader from 'react-loaders';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';

import AnimatedLetters from '../AnimatedLetters'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const form = useRef();
  const titlePage = 'Contact me'.split('');
  const position = [50.081806, 14.4524105];
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  })

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm('service_9oxkixm', 'template_r3ngycw', form.current, 'fw5wjPvTyf2HeONUw')
      .then(
        () => {
          alert('Message successfully sent!')
          navigate('/');
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
  }

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={titlePage}
              idx={15}
            />
          </h1>
          <p>
            Feel free to reach out if you'd like to automate a task in your daily
            life, if you have an idea for a collaborative project,
            or simply to say hello.
          </p>
          <p>
            There is a form right below for your convenience! 😉
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input placeholder="Name" type="text" name="name" required />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="info-map">
          Luc Tatu,
          <br />
          Czech Republic,
          <br /> <br />
          Čajkovského 1423/13 <br />
          130 00 Praha 3-Žižkov <br />
          Praha <br />
        </div>
        <div className="map-wrap">
          <MapContainer center={position} zoom={13}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={position}>
              <Popup>Luc lives here, come over for a cup of coffee 😃</Popup>
            </Marker>
          </MapContainer>
        </div>
      </div>
      <Loader type="pacman" active />
    </>
  )
}

export default Contact
