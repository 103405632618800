import './index.scss';

import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLoginWithEmail = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      alert("Error logging: \n" + error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="password-toggle">
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="bottom-container">
          {showPassword ? (
            <div className="icon-container">
              <FontAwesomeIcon icon={faEye} color="#4d4d4e" onClick={togglePasswordVisibility} />
            </div>
          ) : (
            <div className="icon-container">
              <FontAwesomeIcon icon={faEyeSlash} onClick={togglePasswordVisibility} />
            </div>
          )}
          <div className="empty" />
          <button className="loginBtn" onClick={handleLoginWithEmail}>LogIn</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
