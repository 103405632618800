import './index.scss';

import { useRef } from 'react';
import { storage, db } from '../../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, collection } from 'firebase/firestore';

const Index = () => {
    const form = useRef();

    const submitPortfolio = (e) => {
        e.preventDefault();
        const name = form.current[0]?.value || '';
        const description = form.current[1]?.value || '';
        const skills = form.current[2]?.value || '';
        const url = form.current[3]?.value || '';
        const image = form.current[4]?.files[0];

        if (name && description && image) {
            const  storageRef = ref(storage, `portfolio/${image.name}`);

            uploadBytes(storageRef, image).then(
              (snapshot) => {
                  getDownloadURL(snapshot.ref).then(async (downloadUrl) => {
                      const project = {
                          name,
                          description,
                          skills,
                          url,
                          image: downloadUrl
                      };
                      try {
                          await addDoc(collection(db, 'portfolio'), project);
                          alert("New project data sent to firebase:\n" + project.name)
                      } catch (error) {
                          alert("Failed to add portfolio:\n" + error);
                      }
                  })
              },(error) => {
                  console.error(error);
              }
            )
        } else {
            alert('Name, description, and image are required.');
        }
    }

    return (
      <div className="new-project-container">
          <h1 className="new-project-title">New Project</h1>
          <div className="new-project-form">
              <form ref={form} onSubmit={submitPortfolio}>
                  <p><input type="text" placeholder="Name" /></p>
                  <p><textarea placeholder="Description"  rows="6" /></p>
                  <p><input type="text" placeholder="Skills" /></p>
                  <p><input type="text" placeholder="Url" /></p>
                  <p><input type="file" placeholder="Image" /></p>
                  <button type="submit">Submit</button>
              </form>
          </div>
      </div>
    )
}

export default Index;
