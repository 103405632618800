import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyArb3qBBa1FAfJfuI6Ui9iWf6_sPebub_g",
  authDomain: "myportfolio-a2eff.firebaseapp.com",
  projectId: "myportfolio-a2eff",
  storageBucket: "myportfolio-a2eff.appspot.com",
  messagingSenderId: "1025119361010",
  appId: "1:1025119361010:web:4aa363058eac362e5cbd6b"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);
