import './index.scss';

import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDocker,
  faGitlab,
  faJava,
  faNodeJs,
  faPython,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders';

import AnimatedLetters from '../AnimatedLetters';

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const titlePage = 'About me'.split('');

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  })

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={titlePage}
              idx={15}
            />
          </h1>
          <h5>
            <p>
              Passionate about emerging technologies and deeply involved in
              computer science, I focus on developing projects for skill
              enhancement or personal needs. As a Java backend developer, I
              specialize in automating tasks through scripting to optimize
              efficiency.
            </p>
            <p align="LEFT">
              I'm curious about artificial intelligence, aiming to understand
              its intricate workings and explore potential applications.
              Driven by a desire to learn, I highly value collaborative teamwork
              and human exchanges for personal and professional growth.
            </p>
            <p>
              Originally from Marseille, France, I love to travel.
              I lived in the U.S. for a year and currently I am living in Prague.
              I aim to explore Europe before discover the wonders of Asia.
              Beyond technology, each trip is a chance to broaden my horizons
              and immerse myself in diverse cultures.
            </p>
            <p>
              In essence, I'm a passionate developer with an insatiable appetite
              for learning, always ready to tackle new challenges and explore
              technology's ever-evolving frontiers.
            </p>
          </h5>
        </div>

        <div className="stage-cube-cont">
          <div className="cube-spinner">
            <div className="face1">
              <FontAwesomeIcon icon={faJava} color="#DB534C" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faPython} color="#3776AB" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faDocker} color="#0DB7ED" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#61DAFB" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faNodeJs} color="#8CC84B" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitlab} color="#FC6D26" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman"  active/>
    </>
  )
}

export default About;