import "./index.scss";

import React, { useEffect } from "react";
import TagCloud from "TagCloud";
import file from "../../data/skills.json";

const TextSphere = () => {
  useEffect(() => {
    const container = '.tagcloud';
    const skills = file.skills;

    const options = {
      radius: 300 ,
      maxSpeed: 'normal',
      initSpeed: 'normal',
      keep: true,
    };

    TagCloud(container, skills, options);
  }, []);

  return (
    <>
      <div className="text-sphere">
        <span className="tagcloud"></span>
      </div>
    </>
  );
};

export default TextSphere;
